<template>
  <div class="centerbox">
    <div class="addtxt">新闻编辑</div>
    <div style="margin-left: 5%">
      <el-form ref="form" label-width="80px">
        <el-form-item label="文章标题">
          <el-input v-model="title" class="titleinput inputH"></el-input>
          <span class="must">必填</span>
        </el-form-item>
        <el-form-item label="文章类型">
          <span class="must">
            <el-radio v-model="radio" label="1">普通</el-radio>
            <el-radio v-model="radio" label="2">视频</el-radio>
          </span>
        </el-form-item>
        <el-form-item label="所属栏目">
          <!-- <el-select
            v-model="region"
            placeholder="请选择所属栏目"
            class="columnselect"
          >
            <el-option label="工会之家" value="shanghai"></el-option>
          </el-select> -->
          <el-select v-model="region" @change="regionChange()">
            <el-option
              v-for="val in regionlist"
              :key="val.cid"
              :value="val.cid"
              :label="val.cname"
            />
          </el-select>
          <span class="must">必填</span>
        </el-form-item>

        <el-form-item label="缩略图">
          <!-- <el-upload
            class="uploadbox"
            action=""
            accept=".jpg,.png"
            :file-list="fileList"
            :on-success="scimg"
            :http-request="ImgUploadSectionFile"
            list-type="picture"
            name="img"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div v-if="ifnpic">
              <img
                :src="'https://img.hxsh.ikoo.store/' + npicimg"
                class="nicimg"
                alt=""
              />
            </div>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload> -->
          <Upload
            name="img"
            :on-success="successImg"
            action="https://api.hxsh.ikoo.store/api/Common/UpLoadImage"
          >
            <Button
              style="border-color: #53cac3; color: #7f7f7f"
              class="title-btn fourSize"
              >上传图片</Button
            >
          </Upload>
          <img class="nicimg" :src="'http://img.hxsh.ikoo.store/' + npicimg" />
        </el-form-item>
        <el-form-item label="权重">
          <el-input v-model="weight" class="currencyinput inputH"></el-input>
          <span class="must">必填</span>
        </el-form-item>

        <el-form-item label="阅读基数">
          <el-input v-model="base" class="currencyinput inputH"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="作者">
          <el-input v-model="author" class="currencyinput inputH"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="来源">
          <el-input v-model="source" class="currencyinput inputH"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="关键字">
          <el-input v-model="keyword" class="currencyinput inputH"></el-input>
          <span class="wrongmust">选填,多个用 “,” 号隔开</span>
        </el-form-item>

        <el-form-item label="原文链接">
          <el-input v-model="fromurl" class="titleinput inputH"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="二维码">
          <Upload
            name="img"
            :on-success="successImg2"
            action="https://api.hxsh.ikoo.store/api/Common/UpLoadImage"
          >
            <Button
              style="border-color: #53cac3; color: #7f7f7f"
              class="title-btn fourSize"
              >上传图片</Button
            >
          </Upload>
          <!-- <img :src="'http://img.hxsh.ikoo.store/' + qrcode" /> -->
        </el-form-item>

        <el-form-item label="文章摘要">
          <el-input
            type="textarea"
            v-model="abstract"
            class="textinput"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章内容">
          <div>
            <el-form ref="form" v-model="editorContent">
              <el-form-item class="content" v-model="editorContent">
                <div
                  style="border: 1px solid #ccc; width: 85%"
                  id="editor"
                  ref="editor"
                  :v-model="html2"
                />
              </el-form-item>
            </el-form>
          </div>
          <!-- <div style="border: 1px solid #ccc; width: 85%">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div> -->
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即修改</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import E from "wangeditor";
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "<p>输入正文内容</p>",
      html2: "",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
      ifnpic: false,
      ifqrcode: false,
      title: "",
      region: "",
      weight: "", //权重
      base: "", //阅读基数
      author: "", //作者
      from: "", //来源
      keyword: "", //关键字
      fromurl: "", //原文链接
      abstract: "", //文章摘要
      content: "", // 文章内容
      type: [],
      radio: "1",
      fileList: [],
      pageid: "",
      source: "",
      npicimg: "",
      qrcode: "",
      id: "",
      baseUrl: "https://api.hxsh.ikoo.store/api/Common/UpLoadImage",
      editorContent: "",
      form: {},
      regionlist: "",
    };
  },
  created() {
    this.pageid = this.$route.query.id;
    this.getdetails();
  },
  mounted() {
    this.getclassid();
    this.wangeditor();
    // this.toolbarConfig.excludeKeys = [
    //   "group-video", // 排除菜单组，写菜单组 key 的值即可
    // ];
    // // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = "<p>输入正文内容</p>";
    // }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    // onCreated(editor) {
    //   this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    // },
    wangeditor() {
      var that = this;
      // const editor = new E(this.$refs.editor);
      this.editor = new E(this.$refs.editor);
      // 删除上传视频功能
      this.editor.config.menus.splice(18, 1);
      // 上传图片
      this.editor.config.uploadImgShowBase64 = false; //如果为true，则不用配置下面的
      this.editor.config.uploadImgServer = this.baseUrl; // 这是服务器端上传图片的接口路径
      this.editor.config.uploadFileName = "img"; // 后端接受上传文件的参数名
      // editor.config.uploadImgHeaders = { 'Authorization': 'Bearer ' + getToken() }// 设置请求头
      this.editor.config.uploadImgHooks = {
        customInsert: function (insertImg, result, editor) {
          var url = "http://img.hxsh.ikoo.store/" + result.filename; //当前result.data.filePath为后端返回的半截路径，如果为全路径 则不用拼
          insertImg(url);
        },
      };
      // 上传图片大小 5M
      this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      // // 隐藏插入网络图片的功能
      this.editor.config.showLinkImg = false;
      // // 改变内容触发回调函数
      this.editor.config.onchange = this.editorCallBack;
      // // 配置触发 onchange 的时间频率，默认为 200ms
      this.editor.config.onchangeTimeout = 500; // 修改为 500ms
      this.editor.create();
      this.editor.txt.html(this.html2);
      // 回显已有内容
      this.editor.config.onchange = (html) => {
        this.html2 = html; // 绑定当前逐渐地值 // 将内容同步到父组件中
      };
    },
    // wangeditor回调函数
    editorCallBack(newHtml) {
      this.form.content = newHtml;
    },
    getclassid() {
      this.$request({
        url: "/api/News/M_ClassList",
        method: "GET",
        params: {},
      })
        .then((res) => {
          if (res.data.status) {
            this.regionlist = res.data.list;
          }
        })
        .finally(() => {});
    },
    // 获取文章详情
    getdetails() {
      this.$request({
        url: "/api/News/Detail/" + this.pageid,
        method: "GET",
        params: {},
      })
        .then((res) => {
          if (res.data.status) {
            this.html2 = res.data.news.content;
            this.title = res.data.news.title;
            this.weight = res.data.news.sort;
            this.abstract = res.data.news.descrip;
            this.author = res.data.news.author;
            this.fromurl = res.data.news.fromurl;
            this.npicimg = res.data.news.npic;
            this.qrcode = res.data.news.qrcode;
            this.region = res.data.news.cname;
            this.id = res.data.news.id;
            this.wangeditor();
            if (res.data.news.npic !== "") {
              this.ifnpic = true;
            }
          }
        })
        .finally(() => {});
    },
    clickMenu(item) {
      this.$router.push(
        //页面跳转
        {
          name: demo2,
        }
      );
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    successImg(response, file, fileList) {
      // 上传图片成功后回调
      // console.log(response);
      if (response.result) {
        this.ifnpic = true;
        this.npicimg = response.filename;
      }
    },
    successImg2(response, file, fileList) {
      // 上传图片成功后回调
      // console.log(response);
      if (response.result) {
        this.qrcode = response.filename;
      }
    },
    ImgUploadSectionFile(param) {
      console.log("param", param);
      const formData = new FormData();
      formData.append("formFile", param.file);
      this.$request({
        url: "/api/Common/UpLoadImage",
        method: "POST",
        // headers: {
        //   "content-type": "multipart/form-data;boundary=${boundary}",
        // },
        data: formData,
      })
        .then((res) => {
          this.ifnpic = false;
          if (res.data.status) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
          }
        })
        .finally(() => {});
    },

    onSubmit() {
      if (this.title == "") {
        this.$message.error("文章标题不能为空");
      }
      if (this.region == "") {
        this.$message.error("所属栏目不能为空");
      }
      if (this.title !== "" && this.region !== "" && this.weight !== "") {
        this.$request({
          url: "/api/News/M_NewsEdit",
          method: "POST",
          data: {
            id: this.id,
            title: this.title,
            npic: this.npicimg,
            classid: this.region,
            read: 0,
            ntype: 0,
            sort: this.weight,
            content: this.html2,
            fromurl: this.fromurl,
            descrip: this.abstract,
          },
        })
          .then((res) => {
            if (res.data.status) {
              // console.log(res);
              this.$message({
                message: "修改成功",
                type: "success",
              });
              setTimeout((res) => {
                this.$router.push("/newslist");
              }, 1500);
            }
          })
          .finally(() => {});
      }
    },
  },
};
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  height: auto;
  color: #000;
  overflow: hidden;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
.el-input {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
</style>
<style scoped>
.centerbox {
  background-color: #fff;
}
.addtxt {
  padding: 20px 1%;
}
.must {
  color: red;
  margin-left: 10px;
}
.wrongmust {
  color: #ccc;
  margin-left: 10px;
}
.inputH {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
.titleinput {
  width: 280px;
}
.columnselect {
  width: 160px;
}
.uploadbox {
  width: 300px;
}
.currencyinput {
  width: 160px;
}
.textinput {
  width: 280px;
}
.qrcode {
  width: 120px;
  height: 120px;
}
.nicimg {
  width: 140px;
  height: 140px;
}
</style>
